import KyMethod from "./KyMethod"
import {
  CheckTokenBody,
  ProfileBody,
  RefreshTokenBody,
  RegisterBody,
  ResetPasswordBody,
  SubmitPasswordBody,
} from "@interfaces/auth"

export default class AuthRepositories extends KyMethod {
  protected async rawRegister(body: RegisterBody) {
    return this._crudMethod("/auth/register", body)
  }

  protected async rawLogin({
    user_email,
    user_password,
  }: {
    user_email: string
    user_password: string
  }) {
    return this._crudMethod("/auth/login", { user_email, user_password })
  }

  protected async rawGoogleLogin({
    googleId,
    userFullname,
    email,
  }: {
    googleId: string
    userFullname: string
    email: string
  }) {
    return this._crudMethod("/auth/google", {
      googleId,
      userFullname,
      email,
    })
  }

  protected async rawSubmitGooglePassword(body: SubmitPasswordBody) {
    return this._crudMethod("/auth/submit/password", body)
  }

  protected async rawForgotPassword(email: string) {
    return this._crudMethod("/auth/forgot", { user_email: email })
  }

  protected async rawCheckTokenReset(body: CheckTokenBody) {
    return this._crudMethod("/auth/check_token", body)
  }

  protected async rawResetPassword(body: ResetPasswordBody) {
    return this._crudMethod("/auth/reset", body)
  }

  protected async rawChangePassword(oldPassword: string, newPassword: string) {
    return this._protectedCrudMethod("/user/change_password", {
      method: "post",
      body: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    })
  }

  protected async rawResendVerification(email: string) {
    return this._protectedCrudMethod("/user/resend-verification", {
      method: "post",
      body: {
        user_email: email,
      },
    })
  }

  protected async rawUpdateProfile(body: Partial<ProfileBody>) {
    return this._protectedCrudMethod("/user/profile", {
      method: "put",
      body,
    })
  }

  protected async rawUpdateProfilePicture(body: FormData) {
    return this._protectedCrudMethod("/user/profile/picture", {
      method: "put",
      body,
      bodyType: "form-data",
    })
  }

  protected async rawUpdateInterest(interests: string[]) {
    return this._protectedCrudMethod("/user/profile/interested_courses", {
      method: "put",
      body: {
        user_interested_courses: interests,
      },
    })
  }

  async rawLogout() {
    return this._protectedCrudMethod("/auth/logout")
  }

  protected async rawRefreshToken(body: RefreshTokenBody) {
    return this._protectedCrudMethod("/auth/refresh_tokens", { body })
  }

  protected async rawGetUserData() {
    return this._getProtectedData("/auth/me")
  }
}
