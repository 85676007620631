import React from "react"
import clsx from "clsx"
import Image from "next/image"

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: number
  src: string
}

export const Avatar: React.FC<AvatarProps> = ({ size = 30, src, ...props }) => {
  return (
    <Image
      src={src.length !== 0 ? src : `https://singlecolorimage.com/get/979797/${size}x${size}`}
      className={clsx(props.className, "rounded-full object-cover")}
      placeholder="blur"
      blurDataURL={`https://singlecolorimage.com/get/979797/${size}x${size}`}
      width={size}
      height={size}
      alt={props.alt ?? ""}
    />
  )
}

interface ImageCoverProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: [width: number, height: number]
  src: string
  rounded?: string
}

export const ImageCover: React.FC<ImageCoverProps> = ({
  size,
  src,
  rounded = "rounded",
  ...props
}) => {
  return (
    <Image
      src={src.length !== 0 ? src : `https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}`}
      width={size[0]}
      height={size[1]}
      className={clsx(rounded, "object-cover", props.className)}
      placeholder="blur"
      blurDataURL={`https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}`}
      alt={props.alt ?? ""}
    />
  )
}

// @ts-ignore
// export const BasicImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
//   return <img {...props} alt={props.alt} />
// }

interface BasicImageProps {
  src: string
  width: number
  height: number
  alt: string
  className?: string
}

export const BasicImage: React.FC<BasicImageProps> = ({ src, className, ...props }) => {
  return (
    <Image
      src={src ?? `/1000x1000.png`}
      className={clsx("object-cover", className)}
      placeholder="blur"
      // @ts-ignore
      alt={""}
      blurDataURL={`/1000x1000.png`}
      {...props}
    />
  )
}
