import { useEffect } from "react"
import jwt from "jsonwebtoken"
import nookies from "nookies"

import { GoogleInitializeResponse, IGoogleEndPointResponse } from "@interfaces/google"
import AuthServices from "@lib/api/services/auth.services"

export function useGoogleOneTapLogin() {
  const cookies = nookies.get({})
  const auth = new AuthServices()

  const handleInitialize = async (data: GoogleInitializeResponse) => {
    const decode = jwt.decode(data.credential) as unknown as IGoogleEndPointResponse

    const res = await auth.googleLogin({
      email: decode.email,
      fullName: decode.name,
      googleId: decode.sub,
    })

    if (res && res.isSuccess) {
      const result = res.getValue()

      if (result.new_user === 0) {
        window.location.reload()
      } else {
        window.location.href = "/biodata"
      }
    }
  }

  useEffect(() => {
    const token = cookies.HSTOKENID

    if (!window) {
      return
    }

    window?.google?.accounts?.id?.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID || process.env.HARISENIN_GOOGLE_CLIENT_ID || "",
      callback: handleInitialize,
    })

    if (!token) {
      window?.google?.accounts?.id?.prompt((not: any) => console.log({ not }))
    }
  }, [])
}
