import dayjs from "dayjs"
import "dayjs/locale/id"

export function textShortener(text: string, limit: number): string {
  if (!text) {
    return "-"
  }

  if (text.length > limit) {
    return `${text.substr(0, limit)}...`
  } else {
    return text
  }
}

export function priceFormatter(price: number | string, notFree?: boolean): string {
  if (!price) {
    return notFree ? "Rp 0" : "Gratis"
  } else if (typeof price === "string") {
    return `Rp ${new Intl.NumberFormat("id-ID").format(parseInt(price, 10))}`
  } else {
    return `Rp ${new Intl.NumberFormat("id-ID").format(price)}`
  }
}

export function nameShortener(name: string, limit: number) {
  if (name.length <= limit) {
    return name
  }

  const spliced = name.split(" ")

  const splicedInitial = spliced.map((value) => {
    return value.split("")[0]
  })

  let index = 0
  let isMore = true
  let newName = ""
  const firstName = []

  while (isMore) {
    firstName.push(spliced[index])
    const fn = firstName.join(" ")
    const ln = splicedInitial.slice(index + 1, splicedInitial.length - 1)

    newName = fn + ln + "."

    if (newName.length > limit) {
      firstName.pop()
      newName =
        firstName.join(" ") +
        " " +
        splicedInitial.slice(index, splicedInitial.length).join(". ") +
        "."
      isMore = false
    }

    index++
  }

  return newName
}

export function createMarkup(html?: string): { __html: string } {
  return { __html: html ?? "" }
}

export function calculateAge(birthdate: string | Date) {
  const birthDate = new Date(birthdate)
  const currentDate = new Date()

  let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear()
  const monthsDiff = currentDate.getMonth() - birthDate.getMonth()
  const daysDiff = currentDate.getDate() - birthDate.getDate()

  // Check if the birthdate for the current year has already occurred
  if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
    yearsDiff-- // Subtract one year if the birthdate hasn't occurred yet
  }

  return yearsDiff
}

export function dateFormatter(date: any, format: string) {
  dayjs.locale("id")
  return dayjs(date).format(`${format}`)
}

export function timeConverter(seconds: number) {
  if (isNaN(seconds)) {
    return `00:00`
  }
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, "0")
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
  }
  return `${mm}:${ss}`
}