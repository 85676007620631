import { useGoogleOneTapLogin } from "@hooks/google/useGoogleOneTapLogin"
import { AnalyticsProvider } from "@lib/context/AnalyticsContext"
import { ComponentProvider } from "@lib/context/ComponentContext"
import "dayjs/locale/id"
import { Router } from "next/router"
import nProgress from "nprogress"
import "nprogress/nprogress.css"
import "react-day-picker/lib/style.css"
import "react-multi-carousel/lib/styles.css"
import "react-phone-input-2/lib/style.css"
import "react-phone-number-input/style.css"
import "styles/index.scss"
import Error from "./_error"

const App: ({ Component, pageProps }: { Component: any; pageProps: any }) => JSX.Element = ({
  Component,
  pageProps,
}) => {
  Router.events.on("routeChangeStart", () => {
    nProgress.start()
  })
  Router.events.on("routeChangeComplete", () => {
    nProgress.done()
    // smoothScroll();
  })
  Router.events.on("routeChangeError", () => {
    nProgress.done()
  })

  useGoogleOneTapLogin()

  if (pageProps.error) {
    return <Error />
  }

  return (
    <AnalyticsProvider>
      <ComponentProvider>
        <Component {...pageProps} />
      </ComponentProvider>
    </AnalyticsProvider>
  )
}
export default App
