import clsx from "clsx"
import { Fragment } from "react"
import { FaRegStar, FaRegStarHalf, FaStar, FaStarHalfAlt } from "react-icons/fa"

interface StarRatingProps {
  value: number
  className?: string
}

export const StarRating = ({ value, className }: StarRatingProps) => {
  const rounded = Math.floor(value)
  const element = []

  for (let i = 1; i <= 5; i++) {
    if (i <= rounded) {
      element.push(<FaStar />)
    } else {
      const reduced = Math.abs(value - i)

      if (reduced % 1) {
        element.push(<FaStarHalfAlt key={i} />)
      } else {
        element.push(<FaRegStar key={i} />)
      }
    }
  }

  return (
    <div className={clsx("text-yellow flex", className)}>
      {element.map((value1, i) => {
        return <Fragment key={i}>{value1}</Fragment>
      })}
    </div>
  )
}
