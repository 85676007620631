import React from "react"
import clsx from "clsx"

export const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={clsx("container mx-auto lg:w-[90%] px-4 w-full", className)} {...props}>
      {children}
    </div>
  )
}

export const PageContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={clsx("container mx-auto lg:w-[70%] px-4 max-w-[840px]", className)} {...props}>
      {children}
    </div>
  )
}

export const container = "container mx-auto lg:w-[90%] px-4 w-full"
