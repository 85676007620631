import React, { createContext, FC, useContext, useEffect, useState } from "react"
import jwt from "jsonwebtoken"
import { JWT_SECRET, REFRESH_TOKEN_COOKIES, TOKEN_COOKIES } from "@const/shared"
import nookies from "nookies"
import { useRouter } from "next/router"
import { LoginResponse, RawTokenData, TokenData } from "@interfaces/auth"
import AuthServices from "@lib/api/services/auth.services"

export interface UserContextProps {
  isLogin: number | boolean
  isVerified: number | boolean
  tokenData?: TokenData
  uid: string
  loading: boolean
  token: LoginResponse
}

const ComponentContext = createContext<Partial<UserContextProps>>({})

export const ComponentProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [tokenData, setTokenData] = useState<TokenData | undefined>()
  const [token, setToken] = useState<LoginResponse | undefined>()
  const [uid, setUid] = useState("")

  const cookies = nookies.get({})
  const router = useRouter()
  const auth = new AuthServices()

  useEffect(() => {
    async function loadUserFromCookies(): Promise<void> {
      setLoading(true)
      const token = cookies[TOKEN_COOKIES]
      const refreshToken = cookies[REFRESH_TOKEN_COOKIES]

      if (token) {
        setIsLogin(true)
        jwt.verify(token, JWT_SECRET, { algorithms: ["HS256"] }, async (err, decoded) => {
          if (err) {
            await auth.logout()
            setIsVerified(false)
            window.location.reload()
          } else {
            const data = decoded as RawTokenData
            setIsVerified(true)
            setTokenData(data.data)
            setUid(data.uid)
            setToken({
              token,
              refreshToken,
            })
          }
        })
      } else {
        setIsLogin(false)
      }

      setLoading(false)
    }

    if (cookies && loading) {
      loadUserFromCookies().then(() => null)
    }
  }, [cookies, router, loading])

  return (
    <ComponentContext.Provider value={{ isLogin, isVerified, tokenData, uid, loading, token }}>
      {children}
      {/*{!isLogin ? children : <MaintenancePage />}*/}
    </ComponentContext.Provider>
  )
}

export const useUserData = (): Partial<UserContextProps> => useContext(ComponentContext)
