import { useLocalStorage } from "./useLocalStorage"

export const useWriteKey = () =>
  useLocalStorage(
    "segment_playground_write_key",
    process.env.HARISENIN_SEGMENT_WRITE_KEY || process.env.SEGMENT_WRITE_KEY
  )

export const useCDNUrl = () =>
  useLocalStorage("segment_playground_cdn_url", "https://cdn.segment.com")
