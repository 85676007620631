import React from "react"
import clsx from "clsx"

type JustifyContent = "center" | "between" | "around" | "start" | "end" | "evenly"

type AlignItem = "stretch" | "center" | "start" | "end" | "baseline"

type FlexDirection = "row" | "row-reverse" | "col" | "col-reverse"

type FlexWrap = "wrap" | "wrap-reverse" | "no-wrap"

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  justify?: JustifyContent
  align?: AlignItem
  direction?: FlexDirection
  wrap?: FlexWrap
}

export const FlexBox: React.FC<FlexProps> = ({
  children,
  className,
  justify,
  align,
  direction,
  wrap,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "flex",
        direction && `flex-${direction}`,
        justify && `justify-${justify}`,
        align && `items-${align}`,
        wrap && `flex-${wrap}`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
