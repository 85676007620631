import Head from "next/head"
import useOpenGraphImage, { useFullUrl } from "@lib/helpers/open-graph"

export interface HelmetProps {
  title?: string
  description?: string
  image?: string
  tags?: string
  isIndexed?: boolean
  schemaMarkup?: any[]
}

const Helmet = ({
  title,
  description,
  isIndexed,
  schemaMarkup,
  tags,
}: HelmetProps): JSX.Element => {
  const { imageURL } = useOpenGraphImage()
  const fullURL = useFullUrl()
  return (
    <Head>
      <title>{title || "harisenin.com: Belajar Hemat, Dapat Kerja Cepat"}</title>

      {/*Primary Meta Tags*/}
      <meta
        name="description"
        content={
          description ||
          `Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini`
        }
      />
      <meta name="keywords" content={tags || "bootcamp, jobs, freelance, digital marketing"} />
      <meta name="robots" content={isIndexed ? "index, follow" : "noindex, nofollow"} />
      <meta name="language" content="Indonesian" />
      <meta name="author" content="harisenin.com" />

      {/*Open Graph / Facebook*/}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullURL} />
      <meta
        property="og:title"
        content={title || "harisenin.com: Belajar Hemat, Dapat Kerja Cepat"}
      />
      <meta
        property="og:description"
        content={
          description ||
          "Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini"
        }
      />
      <meta property="og:image" content={imageURL} />
      <meta property="og:site_name" content="Harisenin, PT Karya Kaya Bahagia" />
      <meta
        property="og:image:alt"
        content={title || "harisenin.com: Belajar Hemat, Dapat Kerja Cepat"}
      />

      {/*Twitter*/}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullURL} />
      <meta
        property="twitter:title"
        content={title || "harisenin.com: Belajar Hemat, Dapat Kerja Cepat"}
      />
      <meta
        property="twitter:description"
        content={
          description ||
          "Harisenin.com adalah Online Education Platform yang menawarkan education-to-employment program untuk profesi yang paling banyak dibutuhkan saat ini"
        }
      />
      <meta property="twitter:image" content={imageURL} />
      <meta
        property="twitter:image:alt"
        content={title || "harisenin.com: Belajar Hemat, Dapat Kerja Cepat"}
      />
      <meta property="twitter:site" content="@harisenincom" />

      {/*facebook optional*/}
      <meta property="fb:app_id" content="970446600184538" />
      <meta property="ia:markup_url" content={fullURL} />
      <meta property="ia:markup_url_dev" content={fullURL} />
      <meta property="ia:rules_url" content={fullURL} />
      <meta property="ia:rules_url_dev" content={fullURL} />
      {/* {Boolean(schemaMarkup?.length) &&
        schemaMarkup?.map((s, i) => (
          <script
            key={i}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                s,
              }),
            }}
          />
        ))} */}
    </Head>
  )
}

export default Helmet
