import React, { HTMLAttributes } from "react"
import clsx from "clsx"

export interface BasicCardProps extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string
  customPadding?: string
  customRadius?: string
}

export const BasicCard: React.FC<BasicCardProps> = ({
  backgroundColor,
  customPadding,
  className,
  customRadius,
  children,
  ...props
}) => {
  return (
    <div
      className={clsx(
        customPadding ?? "py-10 px-8",
        customRadius ?? "rounded-10",
        backgroundColor ?? "bg-white",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
